function BlendOffIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="24"
      viewBox="0 0 24 24"
      width="24"
      fill="currentcolor"
    >
      <path d="M0 0h24v24H0V0z" fill="none" />
      <path d="M12 5.1v4.05l7.4 7.4c1.15-2.88.59-6.28-1.75-8.61l-4.94-4.95c-.39-.39-1.02-.39-1.41 0L8.56 5.71l1.41 1.41L12 5.1zm-8.31-.02c-.39.39-.39 1.02 0 1.41l2.08 2.08c-2.54 3.14-2.35 7.75.57 10.68C7.9 20.8 9.95 21.58 12 21.58c1.78 0 3.56-.59 5.02-1.77l2 2c.39.39 1.02.39 1.41 0 .39-.39.39-1.02 0-1.41L5.11 5.08c-.39-.39-1.03-.39-1.42 0zM12 19.59c-1.6 0-3.11-.62-4.24-1.76C6.62 16.69 6 15.19 6 13.59c0-1.32.43-2.56 1.21-3.59L12 14.79v4.8z" />
    </svg>
  );
}

export default BlendOffIcon;
