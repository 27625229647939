const monsters: string[] = [
  "Aboleth",
  "Acolyte",
  "Black Dragon",
  "Blue Dragon",
  "Brass Dragon",
  "Bronze Dragon",
  "Copper Dragon",
  "Gold Dragon",
  "Green Dragon",
  "Red Dragon",
  "Silver Dragon",
  "White Dragon",
  "Air Elemental",
  "Androsphinx",
  "Animated Armor",
  "Ankheg",
  "Ape",
  "Archmage",
  "Assassin",
  "Awakened Shrub",
  "Awakened Tree",
  "Axe Beak",
  "Azer",
  "Baboon",
  "Badger",
  "Balor",
  "Bandit",
  "Bandit Captain",
  "Barbed Devil",
  "Basilisk",
  "Bat",
  "Bearded Devil",
  "Behir",
  "Berserker",
  "Black Bear",
  "Black Pudding",
  "Blink Dog",
  "Blood Hawk",
  "Boar",
  "Bone Devil",
  "Brown Bear",
  "Bugbear",
  "Bulette",
  "Camel",
  "Cat",
  "Centaur",
  "Chain Devil",
  "Chimera",
  "Chuul",
  "Clay Golem",
  "Cloaker",
  "Cloud Giant",
  "Cockatrice",
  "Commoner",
  "Constrictor Snake",
  "Couatl",
  "Crab",
  "Crocodile",
  "Cult Fanatic",
  "Cultist",
  "Darkmantle",
  "Death Dog",
  "Deep Gnome",
  "Deer",
  "Deva",
  "Dire Wolf",
  "Diseased Giant Rat",
  "Djinni",
  "Doppelganger",
  "Draft Horse",
  "Dragon Turtle",
  "Dretch",
  "Drider",
  "Drow",
  "Druid",
  "Dryad",
  "Duergar",
  "Dust Mephit",
  "Eagle",
  "Earth Elemental",
  "Efreeti",
  "Elephant",
  "Elk",
  "Erinyes",
  "Ettercap",
  "Ettin",
  "Fire Elemental",
  "Fire Giant",
  "Flesh Golem",
  "Flying Snake",
  "Flying Sword",
  "Frog",
  "Frost Giant",
  "Gargoyle",
  "Gelatinous Cube",
  "Ghast",
  "Ghost",
  "Ghoul",
  "Giant Ape",
  "Giant Badger",
  "Giant Bat",
  "Giant Boar",
  "Giant Centipede",
  "Giant Constrictor Snake",
  "Giant Crab",
  "Giant Crocodile",
  "Giant Eagle",
  "Giant Elk",
  "Giant Fire Beetle",
  "Giant Frog",
  "Giant Goat",
  "Giant Hyena",
  "Giant Lizard",
  "Giant Octopus",
  "Giant Owl",
  "Giant Poisonous Snake",
  "Giant Rat",
  "Giant Scorpion",
  "Giant Sea Horse",
  "Giant Shark",
  "Giant Spider",
  "Giant Toad",
  "Giant Vulture",
  "Giant Wasp",
  "Giant Weasel",
  "Giant Wolf Spider",
  "Gibbering Mouther",
  "Glabrezu",
  "Gladiator",
  "Gnoll",
  "Goat",
  "Goblin",
  "Gorgon",
  "Gray Ooze",
  "Green Hag",
  "Grick",
  "Griffon",
  "Grimlock",
  "Guard",
  "Guardian Naga",
  "Gynosphinx",
  "Harpy",
  "Hawk",
  "Hell Hound",
  "Hezrou",
  "Hill Giant",
  "Hippogriff",
  "Hobgoblin",
  "Homunculus",
  "Horned Devil",
  "Hunter Shark",
  "Hydra",
  "Hyena",
  "Ice Devil",
  "Ice Mephit",
  "Imp",
  "Incubus",
  "Invisible Stalker",
  "Iron Golem",
  "Jackal",
  "Killer Whale",
  "Knight",
  "Kobold",
  "Kraken",
  "Lamia",
  "Lemure",
  "Lich",
  "Lion",
  "Lizard",
  "Lizardfolk",
  "Mage",
  "Magma Mephit",
  "Magmin",
  "Mammoth",
  "Manticore",
  "Marilith",
  "Mastiff",
  "Medusa",
  "Merfolk",
  "Merrow",
  "Mimic",
  "Minotaur",
  "Minotaur Skeleton",
  "Mule",
  "Mummy",
  "Mummy Lord",
  "Nalfeshnee",
  "Night Hag",
  "Nightmare",
  "Noble",
  "Ochre Jelly",
  "Octopus",
  "Ogre",
  "Ogre Zombie",
  "Oni",
  "Orc",
  "Otyugh",
  "Owl",
  "Owlbear",
  "Panther",
  "Pegasus",
  "Phase Spider",
  "Pit Fiend",
  "Planetar",
  "Plesiosaurus",
  "Poisonous Snake",
  "Polar Bear",
  "Pony",
  "Priest",
  "Pseudodragon",
  "Purple Worm",
  "Quasit",
  "Quipper",
  "Rakshasa",
  "Rat",
  "Raven",
  "Reef Shark",
  "Remorhaz",
  "Rhinoceros",
  "Riding Horse",
  "Roc",
  "Roper",
  "Rug of Smothering",
  "Rust Monster",
  "Saber-Toothed Tiger",
  "Sahuagin",
  "Salamander",
  "Satyr",
  "Scorpion",
  "Scout",
  "Sea Hag",
  "Sea Horse",
  "Shadow",
  "Shambling Mound",
  "Shield Guardian",
  "Shrieker",
  "Skeleton",
  "Solar",
  "Specter",
  "Spider",
  "Spirit Naga",
  "Sprite",
  "Spy",
  "Steam Mephit",
  "Stirge",
  "Stone Giant",
  "Stone Golem",
  "Storm Giant",
  "Succubus",
  "Swarm of Bats",
  "Swarm of Beetles",
  "Swarm of Centipedes",
  "Swarm of Insects",
  "Swarm of Poisonous Snakes",
  "Swarm of Quippers",
  "Swarm of Rats",
  "Swarm of Ravens",
  "Swarm of Spiders",
  "Swarm of Wasps",
  "Tarrasque",
  "Thug",
  "Tiger",
  "Treant",
  "Tribal Warrior",
  "Triceratops",
  "Troll",
  "Tyrannosaurus Rex",
  "Unicorn",
  "Vampire",
  "Vampire Spawn",
  "Veteran",
  "Violet Fungus",
  "Vrock",
  "Vulture",
  "Warhorse",
  "Warhorse Skeleton",
  "Water Elemental",
  "Weasel",
  "Werebear",
  "Wereboar",
  "Wererat",
  "Weretiger",
  "Werewolf",
  "Wight",
  "Will-o'-Wisp",
  "Winter Wolf",
  "Wolf",
  "Worg",
  "Wraith",
  "Wyvern",
  "Xorn",
  "Zombie"
];

export default monsters;

export function getRandomMonster(): string {
  return monsters[Math.floor(Math.random() * monsters.length)];
}
