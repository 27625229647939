function FogCutOffIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="24"
      viewBox="0 0 24 24"
      width="24"
      fill="currentcolor"
    >
      <path d="M0 0h24v24H0V0z" fill="none" />
      <path d="M5.28 3.68l15.56 15.56a1 1 0 01-1.42 1.41l-6.21-6.21-.58 1.2a4 4 0 11-6.17 2.14A4 4 0 118.6 11.6l1.2-.58L3.87 5.1a1 1 0 111.41-1.4zm3.6 13.79a2 2 0 102.83 2.83 2 2 0 00-2.82-2.83zm-4.94-4.95a2 2 0 102.83 2.83 2 2 0 00-2.83-2.83zm6.01 1.76l-.65.32a4 4 0 01-.08.35l-.03.09a4 4 0 01.44-.1l.32-.66zM22.9 6.31l.02.04.02.05c.33.74.01 1.6-.72 1.96l-6.68 3.22-2.24-2.24 7.66-3.7c.72-.35 1.6-.05 1.94.67zM17.84 1.3l.04.01a1.45 1.45 0 01.72 1.97l-1.54 3.18-4.25 1.9L15.88 2a1.5 1.5 0 011.96-.71z" />
    </svg>
  );
}

export default FogCutOffIcon;
