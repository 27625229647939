function D8Icon() {
  return (
    <svg
      width="24"
      height="24"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill="currentcolor"
    >
      <path d="M0 0h24v24H0z" fill="none" />
      <path d="M12.76 1.093l.088.088 8.997 10.156a1 1 0 01.077 1.228l-.077.098-8.997 10.156a1 1 0 01-1.4.096l-.09-.088-9.2-10.156a1 1 0 01-.079-1.243l.08-.1 9.2-10.155a1 1 0 011.4-.08zm-.669 2.249l-7.843 8.657 7.843 8.658L19.759 12l-7.668-8.658zM11.88 8c.981 0 1.707.172 2.176.517.469.345.704.779.704 1.302 0 .305-.094.59-.28.854-.141.197-.361.388-.662.573.397.176.694.408.889.697.195.29.293.61.293.96 0 .34-.084.656-.253.95a1.952 1.952 0 01-.621.68c-.245.16-.55.278-.916.354a5.782 5.782 0 01-1.17.113c-.778 0-1.372-.085-1.783-.254-.41-.17-.723-.419-.936-.748A1.984 1.984 0 019 12.894c0-.397.1-.733.3-1.008.2-.276.51-.49.927-.64-.32-.157-.554-.332-.701-.526-.2-.265-.3-.57-.3-.915 0-.566.288-1.03.866-1.39C10.542 8.14 11.139 8 11.88 8zm.1 3.805a.918.918 0 00-.664.277c-.188.184-.283.434-.283.748a.97.97 0 00.296.732c.197.189.422.284.676.284a.908.908 0 00.656-.289.988.988 0 00.29-.732.982.982 0 00-.292-.734.942.942 0 00-.679-.286zm0-2.66a.855.855 0 00-.604.215.722.722 0 00-.232.556c0 .234.079.423.237.566.16.143.37.215.634.215.234 0 .426-.071.576-.213a.72.72 0 00.225-.55.744.744 0 00-.235-.57.847.847 0 00-.601-.219z" />
    </svg>
  );
}

export default D8Icon;
