function SelectPathIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="24"
      viewBox="0 0 24 24"
      width="24"
      fill="currentcolor"
    >
      <rect fill="none" height="24" width="24" />
      <path d="M5 17a1 1 0 011 1 4 4 0 001.6 3.2 1 1 0 11-1.2 1.6A6 6 0 014 18a1 1 0 011-1zM6.087 16.521a1 1 0 011.322-.504c.898.402 1.815.683 2.744.836a1 1 0 01-.326 1.974 13.145 13.145 0 01-3.236-.984 1 1 0 01-.504-1.322zM12 1c6.031 0 11 3.974 11 9.022a8.19 8.19 0 01-.376 2.278 1 1 0 01-1.908-.6A6.162 6.162 0 0021 10c0-3.811-3.988-7-9-7-5.012 0-9 3.189-9 6.992a5.8 5.8 0 001.143 3.412 1 1 0 11-1.606 1.192A7.806 7.806 0 011 10c0-5.026 4.969-9 11-9z" />
      <path d="M5 13a3 3 0 100 6 3 3 0 000-6zm0 2a1 1 0 110 2 1 1 0 010-2zM14.488 10.35l-.187-.01A1.34 1.34 0 0013 11.68v10c.02.6.39 1.092.926 1.27a1 1 0 00.314.05h.09l.158-.01.153-.034c.343-.09.592-.26.769-.496l2.304-3.45h3.956a1.323 1.323 0 001.26-.884 1.33 1.33 0 00-.434-1.494l-7.323-5.986a1.313 1.313 0 00-.685-.297z" />
    </svg>
  );
}

export default SelectPathIcon;
