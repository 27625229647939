function D20Icon() {
  return (
    <svg
      width="24"
      height="24"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill="currentcolor"
    >
      <path d="M0 0h24v24H0z" fill="none" />
      <path d="M13.014 1.292l7.98 4.577A2 2 0 0122 7.604v8.767a2 2 0 01-.997 1.73l-7.978 4.626a2 2 0 01-2.003.002L3.001 18.1A2 2 0 012 16.369V7.58a2 2 0 011.013-1.74l8.019-4.553a2 2 0 011.982.004zm-.995 1.736L4 7.58v8.788l8.021 4.628L20 16.37V7.604l-7.981-4.576zm3.504 5.256c.491 0 .894.06 1.21.181.315.121.572.278.771.472.2.194.356.397.47.611.115.214.207.463.277.748.136.543.204 1.109.204 1.698 0 1.322-.224 2.289-.672 2.901-.448.613-1.22.92-2.314.92-.614 0-1.11-.099-1.488-.294a2.407 2.407 0 01-.931-.86c-.176-.268-.313-.634-.41-1.1a7.506 7.506 0 01-.147-1.542c0-1.384.25-2.353.749-2.906.499-.553 1.26-.83 2.281-.83zm-6.887 0c.713 0 1.268.08 1.665.243.396.162.708.412.935.748.227.336.341.713.341 1.13 0 .444-.13.867-.39 1.271-.261.404-.735.848-1.421 1.332-.409.281-.681.478-.82.59-.137.113-.299.26-.484.443h3.165v1.649H5.545l.039-.255c.094-.505.293-.984.596-1.436.353-.529 1.016-1.152 1.988-1.87.594-.441.974-.775 1.14-1.004.166-.228.249-.445.249-.65a.76.76 0 00-.247-.57.86.86 0 00-.62-.235.864.864 0 00-.634.243c-.164.162-.274.449-.33.86l-2.031-.165.038-.236c.084-.458.218-.823.4-1.097.212-.32.511-.565.898-.735.386-.17.921-.256 1.605-.256zm6.818 1.346c-.335 0-.578.17-.727.51-.15.338-.224.967-.224 1.884 0 .927.082 1.56.247 1.9.164.34.402.51.714.51a.75.75 0 00.535-.217c.15-.144.262-.372.333-.683.071-.311.107-.796.107-1.455 0-.967-.082-1.617-.246-1.95-.165-.333-.41-.5-.74-.5z" />
    </svg>
  );
}

export default D20Icon;
