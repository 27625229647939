function FogCutOnIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="24"
      viewBox="0 0 24 24"
      width="24"
      fill="currentcolor"
    >
      <path d="M0 0h24v24H0V0z" fill="none" />
      <path d="M2.52 11.1a4 4 0 016.08.5l12.36-5.96c.72-.35 1.6-.05 1.94.67l.02.04.02.05c.33.74.01 1.6-.72 1.96l-7.93 3.83-1.66 3.44a4 4 0 11-6.17 2.14 4 4 0 01-3.94-6.67zm6.37 6.37a2 2 0 102.82 2.83 2 2 0 00-2.82-2.83zm2.82-5.66c-.2.2-.2.51 0 .7.2.2.51.2.71 0 .2-.19.2-.5 0-.7a.5.5 0 00-.7 0zM9.3 14.6a4 4 0 01-.08.35l-.03.09a4 4 0 01.44-.1l.32-.66-.65.32zm8.54-13.3l.04.01a1.45 1.45 0 01.72 1.97l-1.54 3.18-4.25 1.9L15.88 2a1.5 1.5 0 011.96-.71zM3.94 12.52a2 2 0 102.83 2.83 2 2 0 00-2.83-2.83z" />
    </svg>
  );
}

export default FogCutOnIcon;
