function ShapeFillOffIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="24"
      viewBox="0 0 24 24"
      width="24"
      fill="currentcolor"
    >
      <path d="M0 0h24v24H0V0z" fill="none" />
      <path d="M3.414 6.52L16.85 19.953a1 1 0 01-1.414 1.415l-2.644-2.644-1.783 1.783a1.688 1.688 0 01-1.025.484l-.16.008c-.371 0-.751-.128-1.058-.378l-.126-.114-6.147-6.147a1.664 1.664 0 01-.11-2.249l.11-.12 1.782-1.784L2 7.934a1 1 0 111.414-1.415zM7.85 2.695l.097.086 9.21 9.21c.62.62.656 1.597.109 2.25l-.11.12-1.769 1.768-2.954-2.954h2.745L9.824 7.823 8.45 9.195 6.87 7.613l1.379-1.377L6.37 4.358a1.113 1.113 0 010-1.576 1.113 1.113 0 011.479-.086zm-.607 10.48L5.856 11.79 4.47 13.176h2.772zM19.882 21a2.242 2.242 0 002.236-2.235c0-1.487-2.236-3.912-2.236-3.912s-2.235 2.425-2.235 3.912A2.242 2.242 0 0019.882 21z" />
    </svg>
  );
}

export default ShapeFillOffIcon;
