function D6Icon() {
  return (
    <svg
      width="24"
      height="24"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill="currentcolor"
    >
      <path d="M0 0h24v24H0z" fill="none" />
      <path d="M19 3a2 2 0 012 2v14a2 2 0 01-2 2H5a2 2 0 01-2-2V5a2 2 0 012-2h14zm0 2H5v14h14V5zm-6.87 3c.579 0 1.036.075 1.372.224.337.15.616.369.837.657.222.288.391.65.507 1.086l-2.012.277-.029-.15c-.054-.235-.133-.407-.236-.515a.612.612 0 00-.46-.194c-.328 0-.583.185-.765.554-.132.266-.23.835-.293 1.707.242-.273.49-.476.745-.607s.55-.197.884-.197c.65 0 1.198.26 1.647.776.449.517.673 1.173.673 1.967 0 .536-.113 1.025-.34 1.469a2.35 2.35 0 01-.944 1.005c-.402.227-.906.341-1.512.341-.729 0-1.313-.139-1.754-.416-.44-.277-.792-.719-1.055-1.327C9.132 14.05 9 13.245 9 12.244c0-1.466.276-2.54.83-3.222C10.381 8.341 11.148 8 12.13 8zm-.016 4.239a.88.88 0 00-.708.349c-.19.233-.285.565-.285.997 0 .44.1.784.298 1.034.199.249.442.374.73.374.265 0 .487-.113.666-.338.178-.226.268-.562.268-1.009 0-.458-.093-.807-.278-1.047-.186-.24-.416-.36-.69-.36z" />
    </svg>
  );
}

export default D6Icon;
