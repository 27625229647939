function D12Icon() {
  return (
    <svg
      width="24"
      height="24"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill="currentcolor"
    >
      <path d="M0 0h24v24H0z" fill="none" />
      <path d="M12.297 2.037l5.578 1.734a1 1 0 01.5.35l3.421 4.504A1 1 0 0122 9.23v5.54a1 1 0 01-.204.605l-3.422 4.504a1 1 0 01-.5.35l-5.577 1.734a1 1 0 01-.594 0l-5.578-1.734a1 1 0 01-.5-.35l-3.421-4.504A1 1 0 012 14.77V9.23a1 1 0 01.204-.605L5.626 4.12a1 1 0 01.5-.35l5.577-1.734a1 1 0 01.594 0zM12 4.048L7.059 5.565 4 9.54v4.917l3.059 3.976 4.94 1.517 4.941-1.517L20 14.457V9.542l-3.06-3.977L12 4.048zM10.364 8v7.636h-2.14v-5.003l-.258.185a6.092 6.092 0 01-.748.444A7.92 7.92 0 016 11.723v-1.71l.263-.088c.595-.21 1.066-.456 1.415-.737A3.37 3.37 0 008.613 8h1.75zm4.417 0c.768 0 1.365.084 1.792.251.426.167.762.424 1.007.77.244.347.366.736.366 1.166 0 .457-.14.895-.42 1.311-.28.417-.79.874-1.53 1.373-.438.29-.732.493-.88.61a7.954 7.954 0 00-.523.455H18v1.7h-6.545l.035-.23a3.856 3.856 0 01.647-1.514c.38-.544 1.094-1.187 2.14-1.928.64-.454 1.048-.799 1.227-1.034.178-.236.268-.46.268-.671a.768.768 0 00-.265-.587.949.949 0 00-.667-.243c-.279 0-.506.084-.683.25-.177.168-.296.464-.356.887l-2.186-.17.041-.243c.091-.472.235-.849.43-1.131.23-.33.551-.582.967-.758.416-.176.992-.264 1.728-.264z" />
    </svg>
  );
}

export default D12Icon;
