function SocialRedditIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="24"
      viewBox="0 0 24 24"
      width="24"
      fill="currentcolor"
    >
      <path d="M12 0c6.627 0 12 5.373 12 12s-5.373 12-12 12S0 18.627 0 12 5.373 0 12 0zm5 4.75c-.491 0-.912.286-1.116.698l-2.908-.619a.312.312 0 00-.37.24l-.891 4.186c-1.863.052-3.537.61-4.749 1.483a1.75 1.75 0 10-1.926 2.858c-.027.174-.042.35-.042.529 0 2.692 3.134 4.875 7 4.875s7-2.183 7-4.875c0-.178-.014-.353-.041-.526a1.75 1.75 0 10-1.923-2.858c-1.196-.863-2.844-1.42-4.68-1.485l.797-3.75 2.605.553A1.247 1.247 0 0018.25 6c0-.69-.56-1.25-1.25-1.25zM9.492 16.095c.538.538 1.688.728 2.51.728.821 0 1.972-.19 2.51-.728a.324.324 0 01.458.458c-.853.852-2.488.918-2.969.918-.48 0-2.115-.066-2.967-.918a.324.324 0 01.458-.458zM14.75 12c.69 0 1.25.561 1.25 1.25 0 .69-.56 1.25-1.25 1.25s-1.25-.56-1.25-1.25c0-.689.56-1.25 1.25-1.25zm-5.5 0c.69 0 1.25.56 1.25 1.25s-.56 1.25-1.25 1.25c-.689 0-1.25-.56-1.25-1.25S8.561 12 9.25 12z" />
    </svg>
  );
}

export default SocialRedditIcon;
