function D100Icon() {
  return (
    <svg
      width="24"
      height="24"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill="currentcolor"
    >
      <path d="M0 0h24v24H0z" fill="none" />
      <path d="M11.148 1.157l.104.076 1.134.949 1.067-.914a1 1 0 011.214-.068l.097.075L23.66 9.09a1 1 0 01.333.63L24 9.84v4.789a1 1 0 01-.255.668l-.088.086-8.895 7.752a1 1 0 01-1.208.08l-.098-.073-1.069-.91-1.138.945a1 1 0 01-1.164.081l-.105-.074-9.61-7.8a1 1 0 01-.362-.648L0 14.609V9.862a1 1 0 01.273-.687l.094-.087 9.61-7.862a1 1 0 011.17-.07zm-.546 2.545L2 10.48v3.5l8.603 6.722L19 13.993v-3.526l-8.398-6.765zm-4.002 5v6H5.325v-3.931l-.154.145c-.152.139-.3.255-.445.35a4.096 4.096 0 01-.726.362v-1.344l.207-.093c.33-.16.595-.345.793-.555.237-.252.423-.564.557-.934H6.6zm3.07 0c.356 0 .65.048.878.145.23.096.416.221.561.376.145.154.259.316.342.486.083.17.15.37.2.596.1.433.149.884.149 1.354 0 1.052-.163 1.823-.488 2.311-.326.488-.887.732-1.682.732-.446 0-.807-.078-1.082-.233a1.823 1.823 0 01-.676-.685c-.128-.214-.227-.506-.299-.877a6.538 6.538 0 01-.106-1.229c0-1.102.181-1.874.544-2.315.363-.44.916-.66 1.659-.66zm5.2 0c.356 0 .65.048.878.145.23.096.416.221.561.376.145.154.259.316.342.486.083.17.15.37.2.596.1.433.149.884.149 1.354 0 1.052-.163 1.823-.488 2.311-.326.488-.887.732-1.682.732-.446 0-.807-.078-1.082-.233a1.823 1.823 0 01-.676-.685c-.128-.214-.227-.506-.299-.877a6.538 6.538 0 01-.106-1.229c0-1.102.181-1.874.544-2.315.363-.44.916-.66 1.659-.66zm-5.252 1c-.3 0-.517.142-.65.424-.134.283-.201.806-.201 1.57 0 .772.073 1.3.22 1.582.147.283.36.424.64.424.184 0 .343-.06.479-.18.135-.12.234-.31.298-.569.064-.259.096-.663.096-1.212 0-.805-.074-1.346-.22-1.623-.148-.277-.368-.416-.662-.416zm5.2 0c-.3 0-.517.142-.65.424-.134.283-.201.806-.201 1.57 0 .772.073 1.3.22 1.582.147.283.36.424.64.424.184 0 .343-.06.479-.18.135-.12.234-.31.298-.569.064-.259.096-.663.096-1.212 0-.805-.074-1.346-.22-1.623-.148-.277-.368-.416-.662-.416z" />
    </svg>
  );
}

export default D100Icon;
